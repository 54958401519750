import { get, post, put, deletefn } from '@/http/http'

// 维保查询
export const getMainNance = p => get('v1/maintenance/getmaintenance', p)

// 维保添加
export const addMainNance = p => post('v1/maintenance/addmaintenance', p)

// 维保修改
export const editMainNance = p => put('v1/maintenance/updatemaintenance', p)

// 维保删除
export const deleteMainNance = p => deletefn('v1/maintenance/deletemaintenance', p)

// 维保人员查询
export const perGetMN = p => get('v1/maintenance/getmainuser', p)

// 维保人员添加
export const perAddMN = p => post('v1/maintenance/addmainuser', p)

// 绑定单位查询
export const getMCompany = p => get('v1/maintenance/getmusercom', p)
