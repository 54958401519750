// 单位管理
import { get, post, put, deletefn } from '@/http/http'

// 消防队查询
export const fireTeam = p => get('v1/fireinfo/getfireinfo', p)

// 维保查询
export const repairQuery = p => get('v1/maintenance/getmaintenance', p)

// 单位查询
export const unitQuery = p => get('v1/company/getcompany', p)

// 单位添加
export const unitAdd = p => post('v1/company/addcompany', p)

// 单位编辑
export const unitEdit = p => put('v1/company/updatecompany', p)

// 单位删除
export const unitDelete = p => deletefn('v1/company/deletecompany', p)

// 单位类型查询
export const unitTypeQuery = p => get('v1/company/getcompany_type', p)

// 单位下拉选择框查询
export const unitDownQuery = p => get('v1/company/getcompany_drop', p)

// 单位评分
export const getcompanySorce = p => get('v1/company/getcompanysorce', p)

// 单位导出
export const unitExport = p => post('v1/exportfile/export_company', p)

// 报表查询
export const reportForm = p => get('v1/comdetails/getreport', p)

// ai告警查询
export const aialarmInfo = p => post('v1/comdetails/aialarminfo', p)

// ai数据统计
export const aiequStatis = p => post('v1/comdetails/aiequstatis', p)

// ai参数统计
export const aimeterStatis = p => post('v1/comdetails/aimeterstatis', p)

// ai今日抓拍
export const aitodayImg = p => post('v1/comdetails/aitodayimg', p)

// ai历史抓拍
export const aihistoryImg = p => post('v1/comdetails/aihistoryimg', p)

// 抓拍图片获取
export const aigetImg = p => post('v1/comdetails/aigetimg', p)
