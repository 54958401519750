<template>
<div class="setting_main unitContent">
  <div class="unit_top flexCB">
    <div class="ut_item unItem1" v-for="(item,eq) in unitType" :key="item.index" :class="'unItem'+ (eq+1)">
      <p v-if="eq === 0"><img src="@/assets/img/sett_u1.png"/> </p>
      <p v-if="eq === 1"><img src="@/assets/img/sett_u2.png"/> </p>
      <p v-if="eq === 2"><img src="@/assets/img/sett_u3.png"/> </p>
      <p v-if="eq === 3"><img src="@/assets/img/sett_u4.png"/> </p>
      <div class="uiRight">
        <p class="uiTitle">{{ item.name }}</p>
        <p class="uiNumber">{{ item.value }}</p>
      </div>
    </div>
  </div>
  <div class="unit_cont">
    <div class="unit_cont_box">
      <div class="btn_group flexAC" v-if="companyid === ''">
        <div class="iconfont iconjia settingAdd" @click="addUnit"><span>新增</span></div>
        <div class="iconfont icondaochu settingExport" @click="exportHand"><span>导出</span></div>
        <div class="el-icon-close settingDelete" v-if="deleteBtnShow" @click="deleteSelect"><span>删除</span></div>
      </div>
      <div class="flexAC ut_top">
        <el-input v-model="cont"  placeholder="请输入名称/法人/地址" class="contInput"></el-input>
        <el-select v-model="unitGrade" placeholder="单位等级" class="utG setSe">
          <el-option v-for="item in gradeList" :key="item.index"
           :label="item.datavalue"
           :value="item.dictid">
            {{ item.datavalue }}</el-option>
        </el-select>
        <el-select v-model="unitFire" placeholder="辖区消防队" class="utF setSe">
          <el-option v-for="item in fireList" :key="item.index"
                     :label="item.firename"
                     :value="item.fireid">
            {{ item.firename }}</el-option>
        </el-select>
        <el-select v-model="nature" placeholder="行业性质" class="utN setSe">
          <el-option v-for="item in natureList" :key="item.index"
                     :label="item.datavalue"
                     :value="item.dictid">
            {{ item.datavalue }}</el-option>
        </el-select>
        <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
        <el-button class="settingReset" @click="handleReset()">重置</el-button>
      </div>
      <!-- 单位表格列表 -->
      <div class="unit_table">
        <el-table
          stripe
          ref="multipleTable"
          :data="unitList"
          style="width: 100%"
          :row-key="getRowKeys"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            prop="companyid"
            :reserve-selection="true">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="单位名称"
            show-overflow-tooltip
            prop="companyname">
          </el-table-column>
          <el-table-column
              label="单位评分"
              prop="companyscore">
          </el-table-column>
          <el-table-column
            label="单位等级"
            prop="companylevelname">
          </el-table-column>
          <el-table-column
            label="辖区消防队"
            show-overflow-tooltip
            prop="firename">
          </el-table-column>
          <el-table-column
            label="行业性质"
            prop="industryname">
          </el-table-column>
          <el-table-column
            label="维保单位"
            show-overflow-tooltip
            prop="mainname">
          </el-table-column>
          <el-table-column
            label="单位法人"
            prop="companyuser">
          </el-table-column>
          <el-table-column
            label="消控室电话"
            show-overflow-tooltip
            prop="companyphone">
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              <el-button v-if="companyid === ''" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button v-if="companyid === ''" type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
  </div>

  <!-- 单位新增 -->
  <el-dialog title="单位新增" :visible.sync="addFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="addFromCancel">
    <el-form :model="addfrom" :rules="formRules" ref="addFrom">
      <div class="flexCB">
        <el-form-item label="单位名称" class="foItem"  prop="companyname">
          <el-input v-model="addfrom.companyname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="统一信用码"  class="foItem">
          <el-input v-model="addfrom.companycode"  placeholder="请输入统一信用码"></el-input>
        </el-form-item>
      </div>
      <div class="flexCB">
        <div class="flexCB foItem">
          <el-form-item label="单位法人" class="foFSet" prop="companyuser">
            <el-input v-model="addfrom.companyuser"  placeholder="请输入单位法人姓名"></el-input>
          </el-form-item>
          <el-form-item label="法人电话" class="foFSet" prop="suser_phone">
            <el-input v-model="addfrom.suser_phone"  placeholder="请输入法人电话"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="消控室电话" class="foItem" prop="companyphone">
            <el-input v-model="addfrom.companyphone"  placeholder="请输入消控室电话"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="单位地址" prop="companyress">
        <el-input v-model="addfrom.companyress" disabled placeholder="请选择单位地址">
          <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
        </el-input>
      </el-form-item>
      <div class="flexCB">
        <div class="flexCB foItem">
          <el-form-item label="行业性质" class="foFSet">
            <el-select v-model="addfrom.industry" placeholder="请选择行业性质" class="adSe">
              <el-option v-for="item in natureList" :key="item.index"
                         :label="item.datavalue"
                         :value="item.dictid">
                {{ item.datavalue }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位等级" class="foFSet">
            <el-select v-model="addfrom.companylevel" placeholder="请选择单位等级" class="adSe">
              <el-option v-for="item in gradeList" :key="item.index"
                         :label="item.datavalue"
                         :value="item.dictid">
                {{ item.datavalue }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="辖区消防队" class="foItem">
          <el-select v-model="addfrom.companyfire" placeholder="请选择辖区消防队" class="adSe" style="width: 100%">
            <el-option v-for="item in fireList" :key="item.index"
                       :label="item.firename"
                       :value="item.fireid">
              {{ item.firename }}</el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flexSC">
        <div class="foItem">
          <p style="line-height: 20px;margin-bottom: 4px;color: rgba(0, 0, 0, 0.85);">单位建筑</p>
          <div class="buildF">
            <div class="settingConfirm buildBtn" @click="addBuild">添加</div>
            <div class="flexAC buildItem" v-for="(item, index) in addfrom.combuilding" :key="index">
              <el-select class="adSe" style="width: 40%" filterable  v-model="item.id" placeholder="请选择单位建筑"  @change="selectBuild(item, index)">
                <el-option v-for="item in buildList" :key="item.index"
                           :label="item.buildname"
                           :value="item.buildid">
                  {{ item.buildname }}</el-option>
              </el-select>
              <el-select style="width: 46%" class="flcSelect adSe"  v-model="item.lcValue" multiple placeholder="层数">
                <el-option
                  v-for="LcI in item.lc"
                  :key="LcI.label"
                  :label="LcI.label"
                  :value="LcI.label">
                </el-option>
              </el-select>
              <i class="el-icon-circle-close" style="margin-left: 10px; font-size: 20px; color:red;cursor: pointer;" v-if="addfrom.combuilding.length > 1" @click="deleteAdd(index)"></i>
            </div>
          </div>
        </div>
        <div class="flexF foItem">
          <div class="flexCB foItem">
            <el-form-item label="维保单位" class="foFSet">
              <el-select v-model="addfrom.maintenance" placeholder="请选择维保单位" class="adSe" style="width: 100%" @change="getMainUser">
                <el-option v-for="item in repairList" :key="item.index"
                           :label="item.mainname"
                           :value="item.mainid">
                  {{ item.mainname }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="维保人员" class="foFSet">
              <el-select v-model="addfrom.mainuserid" placeholder="请选择维保人员" class="adSe" style="width: 100%">
                <el-option v-for="item in mainuserList" :key="item.index"
                           :label="item.username"
                           :value="item.userid">
                  {{ item.username }}</el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="foItem">
            <label class="from_label">单位图片</label>
            <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>
            <p class="img_tip">只支持.jpg.png.jpeg 格式</p>
          </div>
        </div>
      </div>
<!--  old    -->
<!--      <div class="flexCB">-->
<!--        <el-form-item label="单位电话" class="foItem"  prop="companyphone">-->
<!--          <el-input v-model="addfrom.companyphone"  placeholder="请输入单位联系电话"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="占地面积" class="foItem" prop="companyarea">-->
<!--          <el-input v-model="addfrom.companyarea"  placeholder="请输入单位占地面积"></el-input>-->
<!--          <span class="areaSpan">㎡</span>-->
<!--        </el-form-item>-->
<!--      </div>-->
<!--      <el-form-item label="单位地址" prop="companyress">-->
<!--        <el-input v-model="addfrom.companyress" placeholder="请选择单位地址">-->
<!--          <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>-->
<!--        </el-input>-->
<!--      </el-form-item>-->
<!--      <div class="flexCB">-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="行业性质" class="foFSet" prop="industry">-->
<!--            <el-select v-model="addfrom.industry" placeholder="请选择行业性质" class="adSe">-->
<!--              <el-option v-for="item in natureList" :key="item.index"-->
<!--                         :label="item.datavalue"-->
<!--                         :value="item.dictid">-->
<!--                {{ item.datavalue }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="单位等级" class="foFSet" prop="companylevel" >-->
<!--            <el-select v-model="addfrom.companylevel" placeholder="请选择单位等级" class="adSe">-->
<!--              <el-option v-for="item in gradeList" :key="item.index"-->
<!--                         :label="item.datavalue"-->
<!--                         :value="item.dictid">-->
<!--                {{ item.datavalue }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="单位法人" class="foFSet" prop="companyuser">-->
<!--            <el-input v-model="addfrom.companyuser"  placeholder="请输入单位法人姓名"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="法人电话" class="foFSet" prop="suser_phone">-->
<!--            <el-input v-model="addfrom.suser_phone"  placeholder="请输入法人电话"></el-input>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flexCB">-->
<!--        <el-form-item label="隶属消防队" class="foItem" prop="companyfire">-->
<!--          <el-select v-model="addfrom.companyfire" placeholder="请选择隶属消防队" class="adSe" style="width: 100%">-->
<!--            <el-option v-for="item in fireList" :key="item.index"-->
<!--                       :label="item.firename"-->
<!--                       :value="item.fireid">-->
<!--              {{ item.firename }}</el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="维保单位" class="foFSet" prop="maintenance">-->
<!--            <el-select v-model="addfrom.maintenance" placeholder="请选择维保单位" class="adSe" style="width: 100%" @change="getMainUser">-->
<!--              <el-option v-for="item in repairList" :key="item.index"-->
<!--                         :label="item.mainname"-->
<!--                         :value="item.mainid">-->
<!--                {{ item.mainname }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="维保人员" class="foFSet" prop="mainuserid">-->
<!--            <el-select v-model="addfrom.mainuserid" placeholder="请选择维保人员" class="adSe" style="width: 100%">-->
<!--              <el-option v-for="item in mainuserList" :key="item.index"-->
<!--                         :label="item.username"-->
<!--                         :value="item.userid">-->
<!--                {{ item.username }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="foItem">-->
<!--        <label class="from_label">单位图片</label>-->
<!--        <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>-->
<!--        <p class="img_tip">只支持.jpg.png.jpeg 格式</p>-->
<!--      </div>-->
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="addFromCancel">取消</div>
      <div class="settingConfirm" @click="AddFromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 定位添加 -->
  <el-dialog class="gyDialog mapDialog" :visible.sync="mapShow" width="600px"  :close-on-click-modal="false" :before-close="AddressClose">
    <Address :modify="modifyAdd" @close="AddressClose" @save="AddressSave"></Address>
  </el-dialog>

  <!-- 单位编辑 -->
  <el-dialog title="单位编辑" :visible.sync="editFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="editFromCancel">
    <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
      <div class="flexCB">
        <el-form-item label="单位名称" class="foItem"  prop="companyname">
          <el-input v-model="editfrom.companyname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="统一信用码"  class="foItem">
          <el-input v-model="editfrom.companycode"  placeholder="请输入统一信用码"></el-input>
        </el-form-item>
      </div>
      <div class="flexCB">
        <div class="flexCB foItem">
          <el-form-item label="单位法人" class="foFSet" prop="companyuser">
            <el-input v-model="editfrom.companyuser"  placeholder="请输入单位法人姓名"></el-input>
          </el-form-item>
          <el-form-item label="法人电话" class="foFSet" prop="suser_phone">
            <el-input v-model="editfrom.suser_phone"  placeholder="请输入法人电话"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="消控室电话" class="foItem" prop="companyphone">
            <el-input v-model="editfrom.companyphone"  placeholder="请输入消控室电话"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="单位地址" prop="companyress">
        <el-input v-model="editfrom.companyress" placeholder="请选择单位地址" disabled>
<!--          <el-button slot="append" icon="el-icon-location" @click="showEditRess" ></el-button>-->
          <el-button slot="append" icon="el-icon-location" @click="showEditRess"></el-button>
        </el-input>
      </el-form-item>
      <div class="flexCB">
        <div class="flexCB foItem">
          <el-form-item label="行业性质" class="foFSet">
            <el-select v-model="editfrom.industry" placeholder="请选择行业性质" class="adSe">
              <el-option v-for="item in natureList" :key="item.index"
                         :label="item.datavalue"
                         :value="item.dictid">
                {{ item.datavalue }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位等级" class="foFSet" >
            <el-select v-model="editfrom.companylevel" placeholder="请选择单位等级" class="adSe">
              <el-option v-for="item in gradeList" :key="item.index"
                         :label="item.datavalue"
                         :value="item.dictid">
                {{ item.datavalue }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="辖区消防队" class="foItem" >
          <el-select v-model="editfrom.companyfire" placeholder="请选择辖区消防队" class="adSe" style="width: 100%">
            <el-option v-for="item in fireList" :key="item.index"
                       :label="item.firename"
                       :value="item.fireid">
              {{ item.firename }}</el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flexSC">
        <div class="foItem">
          <p style="line-height: 20px;margin-bottom: 4px;color: rgba(0, 0, 0, 0.85);">单位建筑</p>
          <div class="buildF">
            <div class="settingConfirm buildBtn" @click="editBuild">添加</div>
            <div class="flexAC buildItem" v-for="(item, index) in editfrom.combuilding" :key="index">
              <el-select style="width: 40%" class="adSe" filterable  v-model="item.id" placeholder="请选择单位建筑"  @change="selEditBuild(item, index)">
                <el-option v-for="item in buildList" :key="item.index"
                           :label="item.buildname"
                           :value="item.buildid">
                  {{ item.buildname }}</el-option>
              </el-select>
              <el-select style="width: 46%" class="flcSelect adSe"  v-model="item.lcValue" multiple placeholder="层数">
                <el-option
                  v-for="LcI in item.lc"
                  :key="LcI.label"
                  :label="LcI.label"
                  :value="LcI.label">
                </el-option>
              </el-select>
              <i class="el-icon-circle-close" style="margin-left: 10px; font-size: 20px; color:red;cursor: pointer;" v-if="editfrom.combuilding.length > 1" @click="delEditAdd(index)"></i>
            </div>
          </div>
        </div>
        <div class="flexF foItem">
          <div class="flexCB foItem">
            <el-form-item label="维保单位" class="foFSet">
              <el-select v-model="editfrom.maintenance" placeholder="请选择维保单位" class="adSe" style="width: 100%" @change="getMainUser">
                <el-option v-for="item in repairList" :key="item.index"
                           :label="item.mainname"
                           :value="item.mainid">
                  {{ item.mainname }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="维保人员" class="foFSet">
              <el-select v-model="editfrom.mainuserid" placeholder="请选择维保人员" class="adSe" style="width: 100%">
                <el-option v-for="item in editRepairList" :key="item.index"
                           :label="item.username"
                           :value="item.userid">
                  {{ item.username }}</el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="foItem">
            <label class="from_label">单位图片</label>
            <Upload :fileType="'jpg/png/jpeg'" :picture="editfrom.companyimage" @uploadSuccess="editImgSuccess"></Upload>
            <p class="img_tip">只支持.jpg.png.jpeg 格式</p>
          </div>
        </div>
      </div>
<!--      <div class="flexCB">-->
<!--        <el-form-item label="单位电话" class="foItem"  prop="companyphone">-->
<!--          <el-input v-model="editfrom.companyphone"  placeholder="请输入单位联系电话"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="占地面积" class="foItem" prop="companyarea">-->
<!--          <el-input v-model="editfrom.companyarea"  placeholder="请输入单位占地面积"></el-input>-->
<!--          <span class="areaSpan">㎡</span>-->
<!--        </el-form-item>-->
<!--      </div>-->
<!--      <el-form-item label="单位地址" prop="companyress">-->
<!--        <el-input v-model="editfrom.companyress" placeholder="请选择单位地址" disabled>-->
<!--          <el-button slot="append" icon="el-icon-location"></el-button>-->
<!--        </el-input>-->
<!--      </el-form-item>-->
<!--      <div class="flexCB">-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="行业性质" class="foFSet" prop="industry">-->
<!--            <el-select v-model="editfrom.industry" placeholder="请选择行业性质" class="adSe">-->
<!--              <el-option v-for="item in natureList" :key="item.index"-->
<!--                         :label="item.datavalue"-->
<!--                         :value="item.dictid">-->
<!--                {{ item.datavalue }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="单位等级" class="foFSet" prop="companylevel" >-->
<!--            <el-select v-model="editfrom.companylevel" placeholder="请选择单位等级" class="adSe">-->
<!--              <el-option v-for="item in gradeList" :key="item.index"-->
<!--                         :label="item.datavalue"-->
<!--                         :value="item.dictid">-->
<!--                {{ item.datavalue }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="单位法人" class="foFSet" prop="companyuser">-->
<!--            <el-input v-model="editfrom.companyuser"  placeholder="请输入单位法人姓名"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="法人电话" class="foFSet" prop="suser_phone">-->
<!--            <el-input v-model="editfrom.suser_phone"  placeholder="请输入法人电话"></el-input>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flexCB">-->
<!--        <el-form-item label="隶属消防队" class="foItem" prop="companyfire">-->
<!--          <el-select v-model="editfrom.companyfire" placeholder="请选择隶属消防队" class="adSe" style="width: 100%">-->
<!--            <el-option v-for="item in fireList" :key="item.index"-->
<!--                       :label="item.firename"-->
<!--                       :value="item.fireid">-->
<!--              {{ item.firename }}</el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <div class="flexCB foItem">-->
<!--          <el-form-item label="维保单位" class="foFSet" prop="maintenance">-->
<!--            <el-select v-model="editfrom.maintenance" placeholder="请选择维保单位" class="adSe" style="width: 100%" @change="getMainUser">-->
<!--              <el-option v-for="item in repairList" :key="item.index"-->
<!--                         :label="item.mainname"-->
<!--                         :value="item.mainid">-->
<!--                {{ item.mainname }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="维保人员" class="foFSet" prop="mainuserid">-->
<!--            <el-select v-model="editfrom.mainuserid" placeholder="请选择维保人员" class="adSe" style="width: 100%">-->
<!--              <el-option v-for="item in editRepairList" :key="item.index"-->
<!--                         :label="item.username"-->
<!--                         :value="item.userid">-->
<!--                {{ item.username }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="foItem">-->
<!--        <label class="from_label">单位图片</label>-->
<!--        <Upload :fileType="'jpg/png/jpeg'" :picture="editfrom.companyimage" @uploadSuccess="editImgSuccess"></Upload>-->
<!--        <p class="img_tip">只支持.jpg.png.jpeg 格式</p>-->
<!--      </div>-->
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="editFromCancel">取消</div>
      <div class="settingConfirm" @click="editFromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 定位编辑 -->
  <el-dialog class="mapDialog" :visible.sync="mapEditShow" width="600px" :close-on-click-modal="false" :before-close="AddressClose">
    <Address :modify="modifyAdd" :isNoPca="isNoPca" @close="AddressClose" @save="AddressEditSave"></Address>
  </el-dialog>

  <!-- 单位详情  -->
  <el-dialog :visible.sync="detailShow" width="800px" center>
    <div class="flexAC" style="margin-bottom: 12px;">
      <div class="udi_k"></div>
      <p class="udi_t">单位基本信息</p>
    </div>
    <div class="flexSC">
      <div class="ud_left">
        <p class="udi_p">单位名称：{{ detailInfo.companyname }}</p>
        <p class="udi_p">统一信用码：{{ detailInfo.companycode }}</p>
        <p class="udi_p">消控室电话：{{ detailInfo.companyphone }}</p>
        <p class="udi_p">单位地址：{{ detailInfo.companyress }}</p>
        <div class="flexCB">
          <p class="udi_s">行业性质：{{ detailInfo.industryname }}</p>
          <p class="udi_s">单位等级：{{ detailInfo.companylevelname }}</p>
        </div>
        <div class="flexCB">
          <p class="udi_s">单位法人：{{ detailInfo.companyuser }}</p>
          <p class="udi_s">法人电话：{{ detailInfo.suser_phone }}</p>
        </div>
        <p class="udi_p">辖区消防队：{{ detailInfo.firename }}</p>
        <p class="udi_p">维保单位：{{ detailInfo.mainname }}</p>
      </div>
      <div class="ud_right">
        <img :src="detailInfo.companyimage" :onerror="errorImg" class="uni_img"/>
        <p class="uni_f">单位评分：{{ detailInfo.companyscore }}</p>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import Upload from '@/components/upload.vue'
import Address from '@/components/setting/address.vue'
import { nounSearch } from '@/http/api/settingSystem'
import { fireTeam, unitQuery, repairQuery, unitAdd, unitEdit, unitDelete, unitTypeQuery, unitExport } from '@/http/api/unit'
import { perGetMN } from '@/http/api/maintenance'
import { buildingDrop } from '@/http/api/build'
import { isValDateMobile, isFixedNum } from '@/utils/validate.js'
export default {
  name: 'unitList',
  components: {
    Upload,
    Address
  },
  data () {
    // 信用码校验
    const creditCode = (rule, value, callback) => {
      let Ancode // 统一社会信用代码的每一个值
      let Ancodevalue // 统一社会信用代码每一个值的权重
      let total = 0
      const weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28] // 加权因子
      // 不用I、O、S、V、Z
      const str = '0123456789ABCDEFGHJKLMNPQRTUWXY'
      for (let i = 0; i < value.length - 1; i++) {
        Ancode = value.substring(i, i + 1)
        Ancodevalue = str.indexOf(Ancode)
        total = total + Ancodevalue * weightedfactors[i]
        // 权重与加权因子相乘之和
      }
      let logiccheckcode = 31 - total % 31
      if (logiccheckcode === 31) {
        logiccheckcode = 0
      }
      const Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y'
      const ArrayStr = Str.split(',')
      logiccheckcode = ArrayStr[logiccheckcode]

      const checkcode = value.substring(17, 18)
      if (logiccheckcode !== checkcode) {
        return callback(new Error('校验失败，请输入正确的统一社会信用代码！'))
      }
      return callback()
    }
    // 法人电话校验
    const legalMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入法人电话'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    // 单位电话校验
    const checkPhone = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入单位电话'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    // 名称校验
    const checkRepeatName = (rule, value, callback) => {
      this.unitList.forEach(item => {
        if (item.companyname === value) {
          return callback(new Error('单位名称重复'))
        }
      })
      return callback()
    }
    // 信用码重复校验
    const checkRepeatCode = (rule, value, callback) => {
      this.unitList.forEach(item => {
        if (item.companycode === value) {
          return callback(new Error('社会统一信用码重复'))
        }
      })
      return callback()
    }
    // 单位面积校验
    const checkArea = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入单位面积'))
      }
      if (!isFixedNum(value)) {
        return callback(new Error('请输入整数且可保留两位小数'))
      }
      return callback()
    }
    // 消控室电话校验
    const userphone = (rule, value, callback) => {
      if(value){
        if ( /^1/.test( value ) ) {
          if ( /^1\d{10}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入11位的手机号" ) );
          }
        } else if ( /^0/.test( value ) ) {
          if ( /^0\d{2,4}-?\d{6,9}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入正确的座机号" ) );
          }
        }else{
          callback( new Error( "请输入11位手机号或座机号" ) );
        }
      }else{
          callback();
      }
    }
    return {
      companyid: '',
      cont: '',
      unitGrade: '', // 单位等级id
      gradeList: [], // 单位等级
      unitFire: '', // 隶属消防队id
      fireList: [], // 隶属消防队
      nature: '', // 行业性质id
      natureList: [], // 行业性质
      repairList: [],
      page: 1,
      size: 10,
      total: 0,
      unitList: [],
      addFromShow: false,
      addfrom: {
        companyname: '',
        companycode: '',
        companyphone: '',
        companyress: '',
        adminplan: '',
        industry: '',
        companylevel: '',
        companyuser: '',
        suser_phone: '',
        companylong: '',
        companylat: '',
        companyimage: '',
        companyfire: '',
        maintenance: '',
        mainuserid: '',
        companyarea: '',
        combuilding: [
          { id: '', lc: [], lcValue: '' }
        ]
      },
      formRules: {
        companyname: [
          { required: true, trigger: 'blur', message: '单位名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          { validator: checkRepeatName, message: '单位名称重复', trigger: 'blur' }
        ],
        companycode: [
          { required: true, trigger: 'change', message: '统一社会信用代码不能为空' },
          { type: 'string', max: 18, trigger: 'change', message: '输入有误，统一社会信用代码长度需为18位' },
          { validator: checkRepeatCode, trigger: 'blur' },
          { validator: creditCode, trigger: 'change' }
        ],
        companyphone: [
          // { required: true, validator: checkPhone, trigger: 'blur' }
          { required: false, validator: userphone, trigger: 'change' }
        ],
        companyarea: [
          { required: true, trigger: 'blur', validator: checkArea }
        ],
        companyress: [
          { required: true, trigger: 'change', message: '请选择单位地址' }
        ],
        industry: [
          { required: true, trigger: 'blur', message: '请选择行业性质' }
        ],
        companylevel: [
          { required: true, trigger: 'blur', message: '请选择单位等级' }
        ],
        companyuser: [
          { required: true, trigger: 'blur', message: '单位法人姓名不能为空' }
        ],
        suser_phone: [
          { required: true, validator: legalMobile, trigger: 'blur' }
        ],
        companyfire: [
          { required: true, trigger: 'blur', message: '请选择辖区消防队' }
        ],
        maintenance: [
          { required: true, trigger: 'blur', message: '请选择维保单位' }
        ],
        mainuserid: [
          { required: true, trigger: 'blur', message: '请选择维保人员' }
        ]
      },
      mainuserList: [],
      mapShow: false,
      refresh: false,
      editFromShow: false,
      editfrom: {},
      editformRules: {
        companyname: [
          { required: true, trigger: 'blur', message: '单位名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
          // { validator: checkRepeatName, message: '单位名称重复', trigger: 'blur' }
        ],
        companycode: [
          { required: true, trigger: 'change', message: '统一社会信用代码不能为空' },
          { type: 'string', max: 18, trigger: 'change', message: '输入有误，统一社会信用代码长度需为18位' },
          // { validator: checkRepeatCode, trigger: 'change' },
          { validator: creditCode, trigger: 'change' }
        ],
        companyphone: [
          // { required: true, validator: checkPhone, trigger: 'blur' }
          { required: false, validator: userphone, trigger: 'change' }
        ],
        companyarea: [
          { required: true, trigger: 'blur', validator: checkArea }
        ],
        companyress: [
          { required: true, trigger: 'change', message: '请选择单位地址' }
        ],
        industry: [
          { required: true, trigger: 'blur', message: '请选择行业性质' }
        ],
        companylevel: [
          { required: true, trigger: 'blur', message: '请选择单位等级' }
        ],
        companyuser: [
          { required: true, trigger: 'blur', message: '单位法人姓名不能为空' }
        ],
        suser_phone: [
          { required: true, validator: legalMobile, trigger: 'blur' }
        ],
        companyfire: [
          { required: true, trigger: 'blur', message: '请选择辖区消防队' }
        ],
        maintenance: [
          { required: true, trigger: 'blur', message: '请选择维保单位' }
        ],
        mainuserid: [
          { required: true, trigger: 'blur', message: '请选择维保人员' }
        ]
      },
      editRepairList: [],
      detailShow: false,
      detailInfo: {},
      errorImg: 'this.src="' + require('../../../assets/img/unit_default.png') + '"',
      deleteBtnShow: false,
      deleteListId: '',
      unitType: [],
      buildList: [],
      mapEditShow: false,
      editAddress: '',
      modifyAdd: null, // 地址表单
      isNoPca: false
    }
  },
  watch: {

  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getDropDown() // 下拉选择框
    this.init()
  },
  methods: {
    getDropDown () {
      // 单位等级
      nounSearch({ config: 'unit_level' }).then(res => {
        if (res.status === '1') {
          this.gradeList = res.data
        } else {
          this.gradeList = []
        }
      })
      // 行业性质
      nounSearch({ config: 'nature_industry' }).then(res => {
        if (res.status === '1') {
          this.natureList = res.data
        } else {
          this.natureList = []
        }
      })
      // 隶属消防队
      fireTeam().then(res => {
        if (res.status === '1') {
          this.fireList = res.data.data
        } else {
          this.fireList = []
        }
      })
      // 维保单位
      repairQuery().then(res => {
        if (res.status === '1') {
          this.repairList = res.data.data
        } else {
          this.repairList = []
        }
      })
    },
    init () {
      const params = {
        companyname: this.cont,
        companylevel: this.unitGrade,
        companyfire: this.unitFire,
        industry: this.nature,
        page_index: this.page,
        page_size: this.size
      }
      unitQuery(params).then(res => {
        if (res.status === '1') {
          this.deleteBtnShow = false
          if (res.data.data !== undefined) {
            this.unitList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.unitList = []
            this.total = 0
          }
        } else {
          this.unitList = []
          this.total = 0
        }
      })
      unitTypeQuery().then(res => {
        if (res.status === '1') {
          this.unitType = res.data
        } else {
          this.unitType = []
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 搜索
    handleSearch () {
      this.page = 1
      this.init()
    },
    // 重置
    handleReset () {
      this.page = 1
      this.cont = ''
      this.unitGrade = ''
      this.unitFire = ''
      this.nature = ''
      this.init()
    },
    // 展示添加弹框
    addUnit () {
      this.addFromShow = true
      this.refresh = !this.refresh
      this.mainuserList = []
      this.addfrom.combuilding = [{ id: '', lc: [], lcValue: '', select: false }]
      this.getBuildDown()
      this.modifyAdd = null
    },
    /* 建筑添加 */
    addBuild () {
      let obj = { id: '', lc: [], lcValue: '', select: false }
      this.addfrom.combuilding.push(obj)
    },
    /* 删除 */
    deleteAdd (ind) {
      this.addfrom.combuilding.splice(ind, 1)
    },
    // 建筑下拉
    getBuildDown () {
      buildingDrop().then(res => {
        if (res.status === '1') {
          this.buildList = res.data
        }
      })
    },
    /* 选择建筑 */
    selectBuild (val, eq) {
      this.addfrom.combuilding.forEach((item, i) => {
        if (i !== eq) {
          if (val.id === item.id) {
            this.$message.warning('选择重复，请重新选择')
            this.addfrom.combuilding[eq].id = ''
          }
        } else {
          let ind = this.queryKey(val.id)
          this.addfrom.combuilding[eq].lc = this.buildList[ind].buildfloor
        }
        this.addfrom.combuilding[eq].lcValue = ''
      })
    },
    queryKey (id) {
      let len = 0
      this.buildList.forEach((item, index) => {
        if (id === item.buildid) {
          len = index
        }
      })
      return len
    },
    // 关闭添加弹框
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    addImgSuccess (res) {
      this.addfrom.companyimage = res.filepath
    },
    showAddress () {
      this.mapShow = true
    },
    AddressClose () {
      this.mapShow = false
      this.mapEditShow = false
    },
    // 定位保存-新增表单
    AddressSave (res) {
      this.mapShow = false
      this.modifyAdd = { ...res }
      this.addfrom.companyress = res.address
      this.addfrom.adminplan = res.province + '/' + res.city + '/' + res.area + '/' + res.street
      this.addfrom.companylong = res.longitude
      this.addfrom.companylat = res.latitude
    },
    getMainUser (val) {
      const params = { mainid: val }
      perGetMN(params).then(res => {
        if (res.status === '1') {
          if (this.addFromShow === true) {
            this.mainuserList = res.data
            this.addfrom.mainuserid = ''
          } else if (this.editFromShow === true) {
            this.editRepairList = res.data
            this.editfrom.mainuserid = ''
          }
        }
      })
    },
    getMainUserDf (val) {
      const params = { mainid: val }
      perGetMN(params).then(res => {
        if (res.status === '1') {
          this.editRepairList = res.data
        }
      })
    },
    // 添加单位
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyname: this.addfrom.companyname,
          companycode: this.addfrom.companycode,
          companyphone: this.addfrom.companyphone,
          companyress: this.addfrom.companyress,
          adminplan: this.addfrom.adminplan,
          industry: this.addfrom.industry,
          companylevel: this.addfrom.companylevel,
          companyuser: this.addfrom.companyuser,
          suser_phone: this.addfrom.suser_phone,
          companylong: this.addfrom.companylong,
          companylat: this.addfrom.companylat,
          companyimage: this.addfrom.companyimage,
          companyfire: this.addfrom.companyfire,
          maintenance: this.addfrom.maintenance,
          mainuserid: this.addfrom.mainuserid,
          companyarea: this.addfrom.companyarea,
          combuilding: this.addfrom.combuilding
        }
        unitAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 展示编辑弹框
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
      this.getMainUserDf(this.editfrom.maintenance)
      this.getBuildDown()
      if (this.editfrom.combuilding.length === 0) {
        this.editfrom.combuilding = [{ id: '', lc: [], lcValue: '' }]
      }
    },
    /* 建筑编辑添加 */
    editBuild () {
      let obj = { id: '', lc: [], lcValue: '' }
      this.editfrom.combuilding.push(obj)
    },
    /* 选择建筑 */
    selEditBuild (val, eq) {
      this.editfrom.combuilding.forEach((item, i) => {
        if (i !== eq) {
          if (val.id === item.id) {
            this.$message.warning('选择重复，请重新选择')
            this.editfrom.combuilding[eq].id = ''
          }
        } else {
          let ind = this.queryKey(val.id)
          this.editfrom.combuilding[eq].lc = this.buildList[ind].buildfloor
        }
        this.editfrom.combuilding[eq].lcValue = ''
      })
    },
    delEditAdd (ind) {
      this.editfrom.combuilding.splice(ind, 1)
    },
    /* 编辑地址-保存 */
    AddressEditSave (res) {
      console.log(res)
      this.mapEditShow = false
      this.modifyAdd = { ...res }
      this.editfrom.companyress = res.address
      this.editfrom.adminplan = res.province + '/' + res.city + '/' + res.area + '/' + res.street
      console.log(this.editfrom.adminplan)
      this.editfrom.companylong = res.longitude
      this.editfrom.companylat = res.latitude
    },
    // 关闭编辑弹框
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    /* 展示地址-编辑表单 */
    showEditRess () {
      this.mapEditShow = true
      let pca = this.editfrom.adminplan.split('/')
      let obj = {
        province: pca[0], // 省
        city: pca[1], // 市
        area: pca[2], // 区
        street: pca[3],
        address: this.editfrom.companyress,
        longitude: this.editfrom.companylong,
        latitude: this.editfrom.companylat
      }
      this.modifyAdd = obj
    },
    editImgSuccess (res) {
      this.editfrom.companyimage = res.filepath
    },
    // 编辑
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.editfrom.companyid,
          companyname: this.editfrom.companyname,
          companycode: this.editfrom.companycode,
          companyphone: this.editfrom.companyphone,
          industry: this.editfrom.industry,
          companylevel: this.editfrom.companylevel,
          companyuser: this.editfrom.companyuser,
          suser_phone: this.editfrom.suser_phone,
          companyimage: this.editfrom.companyimage,
          companyfire: this.editfrom.companyfire,
          maintenance: this.editfrom.maintenance,
          mainuserid: this.editfrom.mainuserid,
          companyarea: this.editfrom.companyarea,
          companylong: this.editfrom.companylong,
          companylat: this.editfrom.companylat,
          companyress: this.editfrom.companyress,
          adminplan: this.editfrom.adminplan,
          combuilding: this.editfrom.combuilding
        }
        unitEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        unitDelete({ companyid: row.companyid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 详情
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
    },
    getRowKeys (row) {
      return row.companyid
    },
    handleSelectionChange (val) {
      if (val.length === 0) {
        this.deleteBtnShow = false
        this.deleteListId = ''
      } else {
        this.deleteBtnShow = true
        this.deleteListId = val
      }
    },
    // 多选删除
    deleteSelect () {
      var arr = []
      for (let i = 0; i < this.deleteListId.length; i++) {
        arr.push(this.deleteListId[i].companyid)
      }
      var comId = arr.join(',')
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        unitDelete({ companyid: comId }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
      // console.log(comId)
    },
    // 导出
    exportHand () {
      const params = {
        companyname: this.cont,
        companylevel: this.unitGrade,
        companyfire: this.unitFire,
        industry: this.nature
      }
      unitExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.unitContent{
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.unitContent::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.unit_top{
  width: 100%;
  height: 140px;
  overflow: hidden;
}
.ut_item{
  width: 23%;
  margin-right: 2%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 15px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.unItem1{
  background: linear-gradient(315deg, #FFA764 0%, #FF5C92 100%);
}
.unItem2{
  background: linear-gradient(315deg, #DC64FF 0%, #9B68FF 100%);
}
.unItem3{
  background: linear-gradient(315deg, #64BBFF 0%, #5C77FF 100%);
}
.unItem4{
  background: linear-gradient(315deg, #A5D544 0%, #40C479 100%);
}
.uiRight{
  margin-left: 20px;
}
.uiTitle{
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.uiNumber{
  font-size: 36px;
  line-height: 50px;
  color: #fff;
}
.ut_item:last-child{
  margin-right: 0%;
}
.unit_cont{
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 170px;
  box-sizing: border-box;
}
.unit_cont_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.unit_table{
  margin-top: 20px;
}
.utF{
  margin: 0px 16px;
}
.foItem{
  width: 390px;
}
.foFSet{
  width: 180px;
}
.img_tip{
  font-size: 12px;
  line-height: 16px;
  color: #A5B7CC;
  margin-top: 4px;
}
.ud_left{
  width: 520px;
}
.ud_right{
  width: 160px;
}
/*.udi_p,.udi_s{*/
/*  width: 100%;*/
/*  background: #F6F9FF;*/
/*  color: #333333;*/
/*  margin-bottom: 8px;*/
/*  border-radius: 4px;*/
/*  padding: 9px 12px;*/
/*  box-sizing: border-box;*/
/*}*/
/*.udi_s{*/
/*  width: 48%;*/
/*}*/
.uni_img{
  width: 160px;
  height: 160px;
  border-radius: 10px;
}
.uni_f{
  width: 100%;
  text-align: center;
  color: #333;
  margin-top: 12px;
}
.areaSpan{
  position: absolute;
  right: 15px;
}
.buildF{
  width: 100%;
  position: relative;
}
.buildBtn{
  width: 12%;
  position: absolute;
  right: 0;
}
.buildItem{
  margin-bottom: 10px;
}
.flcSelect{
  margin-left: 5px;
}
.flcSelect >>> .el-select__tags{
  flex-wrap: nowrap;
  overflow: hidden;
}
</style>
