<template>
  <div class="upload_box">
    <el-upload
      v-loading="loading"
      class="uploader"
      accept=".png,.jpeg,.bmp,.gif,.jpg,.PNG,.JPG,.JPEG,.GIF"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :disabled="disabled === undefined ? false : disabled"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <p v-else>
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <span class="tip">上传图片</span>
      </p>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'upload',
  props: ['refresh', 'disabled', 'picture', 'fileType'],
  data () {
    return {
      action: this.BASE_URL + this.$url.PATH.annex,
      loading: false,
      imageUrl: ''
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: this.$url.PATH.Anotherplace
      }
    }
  },
  watch: {
    picture (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    },
    refresh (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    }
  },
  created () {
    if (this.picture === undefined || this.picture === '' || this.picture === null) {
      this.imageUrl = ''
    } else {
      this.imageUrl = this.picture
    }
  },
  methods: {
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const isJPG = 'jpeg/bmp/gif/jpg/PNG/JPG/JPEG/GIF'.includes(type)
      const isJPG = this.fileType.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('请上传支持的图片类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      } else {
        this.loading = true
        return isJPG && isLt50M
      }
    },
    handleAvatarSuccess (res) {
      if (res.status === '1') {
        this.loading = false
        this.imageUrl = res.data.filepath
        this.$emit('uploadSuccess', res.data)
      } else {
        this.$message.error(res.message)
      }
    },
    cancelImg() {
      this.imageUrl = ''
    }
  }
}
</script>

<style scoped>
.uploader,.el-upload{
  width: 105px;
  height: 105px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #F0F3F8;
}
.uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 20px;
  color: #A5B7CC;
  width: 105px;
  height: 105px;
  line-height: 80px;
  text-align: center;
  font-weight: 600;
}
.avatar{
  width: 105px;
  height: 105px;
}
.tip{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  color: #A5B7CC;
}
</style>
