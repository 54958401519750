import { get, put, post } from '@/http/http'

// 设置中心 系统管理  配置管理接口

// 日志查询
export const queryLog = p => get('v1/sysconfig/getlogdetails', p)

// 字典查询
// transmission  传输方式
// nature_industry  行业性质
// unit_level  单位等级
// manufacturer  设备厂家
// buildingtype  建筑类型
// cameramace 摄像头厂家
// inspecycle 巡检周期
export const nounSearch = p => get('v1/sysconfig/getsysconfig', p)

// 获取唯一码
export const getequuuid = p => get('v1/sysconfig/getequuuid', p)

// 通知设置
export const noticeSetup = p => put('v1/userinfo/noticesetup', p)

// 通知查询
export const getnoticeSet = p => get('v1/userinfo/getnoticeset', p)

// 施工手册
export const imPlementAtion = p => post('v1/sysconfig/implementation', p)

// 省
export const provinceQuery = p => post('v1/sysconfig/get_province', p)

// 市
export const cityQuery = p => post('v1/sysconfig/get_city', p)

// 区
export const areaQuery = p => post('v1/sysconfig/get_county', p)

// 街道查询
export const streetQuery = p => post('v1/sysconfig/get_street', p)


