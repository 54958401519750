import { get, post, put, deletefn } from '@/http/http'

// 建筑查询
export const getbuilding = p => get('v1/building/getbuilding', p)

// 建筑添加
export const buildAdd = p => post('v1/building/addbuilding', p)

// 建筑修改
export const buildEdit = p => put('v1/building/updatebuilding', p)

// 建筑删除
export const buildDelete = p => deletefn('v1/building/deletebuilding', p)

// 建筑查询下拉
export const dropbuilding = p => get('v1/building/getbuilding_drop', p)

// 建筑查询 单位 下拉
export const buildingDrop = p => get('v1/building/getbuilding_com', p)
